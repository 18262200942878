<template>
  <button class="button" type="button" @click="$emit('downloadPdf')" :style="background">
    SAVE AS PDF
  </button>
</template>

<script>
export default {
  name: "SaveAsPdfButton",

  props: {
    backgroundColor: {
      type: String,
      default: '#6304D1'
    },
  },

  computed: {
    background(){
      return `background-color: ${this.backgroundColor}`
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @apply transition duration-300 ease-in-out flex items-center text-white uppercase rounded-full px-6 py-3 text-xs font-medium justify-center;

  &:hover {
    @apply bg-white text-black;
  }
}

</style>
