<template>
  <div title="Pronunciation">
    <div>
      <button v-if="! playing" @click.prevent="play()" type="button" class="p-3 focus:outline-none rounded-full focus:shadow-outline border-2" :class="borderColor">
        <PlayAltIcon class="h-4 w-4" />
      </button>
      <button v-else @click.prevent="stop()" type="button" class="p-3 focus:outline-none rounded-full focus:shadow-outline border-2" :class="borderColor">
        <StopIcon class="h-4 w-4" />
      </button>
    </div>

    <audio
      class="hidden"
      ref="audioPlayer"
      @play="playing = true"
      @pause="playing = false"
      playsinline
    ></audio>
  </div>
</template>

<script>
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import StopIcon from '@/components/svgs/StopIcon'

  export default {
    name: 'AudioPreview',

    props: {
      mediaSrc: String,
      borderColor: {
        type: String,
        default: 'border-pink-500',
      }
    },

    components: {
      PlayAltIcon,
      StopIcon,
    },

    data() {
      return {
        playing: false,
        audioLength: 0,
        interval: null,
        currentSeconds: 0,
      }
    },

    watch: {
      playing(playing) {
        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.audioPlayer.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      },

      mediaSrc(mediaSrc) {
        this.playing = false
        this.currentSeconds = 0
        this.audioLength = 0
        this.$refs.audioPlayer.src = mediaSrc
      }
    },

    methods: {
      play() {
        this.$refs.audioPlayer.play()
      },

      stop() {
        this.$refs.audioPlayer.pause();
        this.$refs.audioPlayer.currentTime = 0
      },
    },

    mounted() {
      this.$refs.audioPlayer.src = this.mediaSrc
    }
  }
</script>
