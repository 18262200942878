<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.12524 12C4.12524 11.4477 4.57296 11 5.12524 11H19.0797C19.6319 11 20.0797 11.4477 20.0797 12C20.0797 12.5523 19.6319 13 19.0797 13H5.12525C4.57296 13 4.12524 12.5523 4.12524 12Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "LessIcon"
}
</script>

<style scoped>

</style>
