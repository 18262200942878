<template>
  <Dropdown v-if="profiles.length > 1">
    <template slot="button">
    <div class="button" :style="background">
      See {{FirstName}}'s {{oppositeType}}s
    </div>
    </template>
    <template slot="content">
      <div class="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <router-link v-for="(profile, index) in profiles" :key="index" target="_blank" :to="redirect(profile.id)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
          {{profile.name}}
          </router-link>
        </div>
      </div>
    </template>
  </Dropdown>
  <router-link v-else target="_blank" :to="redirect(profiles[0].id)" class="button" :style="background">
    See {{FirstName}}'s {{oppositeType}}
  </router-link>
</template>

<script>
import Dropdown from "@/components/shared/Dropdown";

export default {
  name: "ViewShowProfilesButton",

  components: {Dropdown},

  props: {
    profiles: Array,
    profileName: String,
    oppositeType: String,
    backgroundColor: {
      type: String,
      default: '#6304D1'
    },
  },

  methods: {
    redirect(id) {
      return `/${this.oppositeType == 'show' ? 'shows' : 'guests'}/${id}`
    }
  },

  computed: {
    background(){
      return `background-color: ${this.backgroundColor}`
    },
    FirstName(){
      return this.profileName.split(" ")[0]
    }
  },
};
</script>

<style lang="scss" scoped>
.button {
  @apply transition duration-300 ease-in-out flex items-center text-white uppercase rounded-full px-6 py-3 text-xs font-medium justify-center;

  &:hover {
    @apply bg-white text-black;
  }
}

</style>
