<template>
  <div>
    <span v-if="profile.is_pro" class="uppercase text-white text-xs rounded-full px-3 py-1 font-semibold absolute right-0 top-0 -mt-5 mr-10" :style="`background-color: ${profile.color ? profile.color : '#B503D0'}`">Pro</span>
    <div class="inline-flex items-center">
      <h2 class="text-2xl font-bold">{{ profile.name }}</h2>

      <span v-if="profile.pronunciation" class="ml-4">
        <AudioPreviewSlim :media-src="profile.pronunciation" class="hover:opacity-70 transition duration-150 ease-in-out" border-color="border-blue-800" />
      </span>
    </div>
    <h2 v-if="profile.title" class="font-normal text-grayHome capitalize mb-3">{{ profile.title }}</h2>
    <div>
      <span v-for="(category, index) in profile.categories" :key="index" class="text-darkGray text-sm uppercase font-medium">
        <span v-if="index > 0"> - </span>  <router-link :to="{name: 'Browse', query: { category: category.id, showing: 'guests' }}" class="hover:text-violet">{{category.name}}</router-link>
      </span>
    </div>
    <profile-rating class="mb-4" :rating="profile.avg_rating" :starSize="22" :activeColor="profile.color ? profile.color : '#B503D0'"
                    inactiveColor="#D4D4D4" :padding="2" />

    <div class="mt-6 flex flex-wrap justify-center space-x-1" v-if="profileReach.length">
      <template v-for="(reach, index) in profileReach">
        <div :key="`reach-${index}`" class="flex items-center bg-white bg-opacity-10 rounded h-9.5 pr-3 mb-4">
          <component :is="`${reach.platform}-icon`" :class="{'h-6 pl-3': reach.platform == 'twitter', 'h-8 pl-1': reach.platform != 'twitter'}" />
          <span class="ml-1 whitespace-nowrap uppercase" :class="{'ml-2': reach.platform == 'twitter'}">
            {{ reach.from }} - {{ reach.to }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProfileRating from '@/components/shared/ProfileRating.vue'
import TwitterIcon from '@/components/svgs/ReachIcons/TwitterIcon';
import TiktokIcon from '@/components/svgs/ReachIcons/TiktokIconBlack';
import YoutubeIcon from '@/components/svgs/ReachIcons/YoutubeIcon';
import InstagramIcon from '@/components/svgs/ReachIcons/InstagramIcon';
import AudioPreviewSlim from '@/components/shared/AudioPreviewSlim';

export default {
  name: 'MobileGeneralInfo',

  props: {
    profile: Object,
    profileReach: Array,
  },

  components: {
    ProfileRating,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
    InstagramIcon,
    AudioPreviewSlim,
  },
}
</script>
