<template>
  <div class="w-full">
    <!-- Header-->
    <div @click="toggle" class="px-6 py-3 bg-guestio-gray-100 flex justify-between items-center cursor-pointer -mx-4 md:mx-0">
      <slot name="title"></slot>
      <button v-if="open">
        <LessIcon data-html2canvas-ignore/>
      </button>
      <button v-else>
        <PlusIcon data-html2canvas-ignore/>
      </button>
    </div>
    <!-- Content-->
    <transition @before-enter="beforeEnter" @enter="enter">
      <div v-if="open" class="px-10 pb-5">
        <ul class="list-disc pl-5">
          <li
            v-for="(item, index) in list"
            class="my-3 text-sm"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import LessIcon from "@/components/svgs/LessIcon"
import PlusIcon from "@/components/svgs/PlusIcon";
import anime from "animejs";

export default {
  name: 'Accordion',
  components: {PlusIcon, LessIcon},
  props: {
    list: Array,
    convert: {type: Boolean, default: false},
  },

  data() {
    return {
      open: false
    }
  },
  methods: {
    setOpen() {
      this.open = true;
    },
    toggle() {
      this.open = !this.open;
    },
    beforeEnter(el) {
      el.style.maxHeight = 0
    },
    enter(el) {
      anime({
        targets: el,
        maxHeight: el.scrollHeight + 20,
        duration: 600,
      });
    }
  },
  watch: {
    convert: function() { // watch it
      this.setOpen();
    }
  },
}
</script>
