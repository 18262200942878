<template>
  <iframe width="100%" height="315" class="rounded-lg"
          :src="video">
  </iframe>
</template>

<script>
  export default {
    name: "Video",
    props: {
      video: String
    }
  }
</script>

